import "@babel/polyfill";
import React from "react";
import { LawNavbar }  from './components/LawNavbar';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import GetEncodedQry from './GetEncodedQry';
import HeroDiv from './components/HeroDiv'; // Adjust the path as needed

//import { Home } from './pages';
import { Search } from './pages/search';
import { BrowseList } from './pages/browse';
//import { About } from './pages/about';
//import { Cities } from './pages/cities';
//import { ChatBot } from './ChatBot';
//import {ContactForm} from './pages/contactform';
import locationData from './components/law-loc-lang-min.json';
import './App.css';


const queryParams = new URLSearchParams(window.location.search);
 const qid = queryParams.get('qid');
 var keyword = queryParams.get('q');
 var loc = queryParams.get('loc')
 var lan = queryParams.get('lang');
 var loc_name = queryParams.get('lname');
 var page = queryParams.get('page');
 var callsrch = 0;
 var fqry = '';
 var pt = 'AI-Ranked Top-Rated Lawyers Near Me | AI-Powered Lawyer Search'; // default
 const ishome = window.location.pathname === "/" ? 1 : 0;
 let location = '';
 let capKeyword = '';
   // Function to capitalize the first letter of each word in a string
  const capitalizeEachWord = (string) => {
    return string.replace(/\b\w/g, char => char.toUpperCase());
  };
 
 if (keyword !=null && loc!=null){
  //formattedQry = id.value + "/" + id.value + "-" + document.getElementById("loc")+ "-"+ document.getElementById("lang");
  callsrch = 1;
  //if(!parseInt(page)) page = 1;
  if(!page) page = 1;
}
else { callsrch = 0; 
    page = 1; 
	// Set default value if keyword is null or empty
	if (!keyword) {
		keyword = "lawyers";
	}
   // keyword = "lawyers";
    }
if (callsrch == 1) { 
    fqry = GetEncodedQry(keyword, loc, lan);
	capKeyword = capitalizeEachWord(keyword);
    pt = "AI-Ranked Top-Rated " + capKeyword + " Near " + loc_name + " | AI-Powered Lawyer Search";
	location = loc_name.split(',')[0].trim();

	document.description = "Find AI-Ranked Top-rated " + capKeyword + " near " + location + " with AI-powered search. Get expert legal help and find the right lawyer for personal injury, divorce, car accidents, medical, tax, probate, family and more. Search, discover and connect with the best " + capKeyword + " from our AI-ranked and screened lawyer directory in " + loc_name 
  }

class App extends React.Component {
     constructor(props) {
        super(props);
  
        this.state = {
            locobj: null,
            gotLoc: false,
            locfound: null
        };
    }

    componentDidMount() {
        if (callsrch === 0) {
  
        try {
  var data;
 // alert('came..');

        fetch('https://lawyerz.com/location')
         .then(response =>response.json())
         .then((cfdata) => {
          this.setState({locobj:cfdata});
          let query = cfdata.cf.city ? cfdata.cf.city.toLowerCase() : '';
          let reg = cfdata.cf.region ? cfdata.cf.region.toLowerCase() : '';
		  
		 // const fairfaxCities = [
			// 'annandale',
			// 'burke',
			// 'centreville',
			// 'chantilly',
			// 'clifton',
			// 'fairfax',
			// 'fairfax station',
			// 'falls church',
			// 'fort belvoir',
			// 'great falls',
			// 'herndon',
			// 'lorton',
			// 'mclean',
			// 'oakton',
			// 'reston',
			// 'springfield',
			// 'vienna'
		// ];
		
		const counties = {
    'virginia': {
        'fairfax county': [
            'annandale', 'burke', 'centreville', 'chantilly', 'clifton', 'fairfax station', 'fairfax', 'falls church',
            'fort belvoir', 'great falls', 'herndon', 'oakton', 'reston', 'mclean', 'vienna', 'springfield'
        ]
    },
    'maryland': {
        'montgomery county': [
            'aspen hill', 'brookeville', 'burtonsville', 'cabin john', 'clarksburg', 
            'damascus', 'derwood', 'garrett park', 'laytonsville', 'sandy spring'
        ]
    },
    'california': {
        'orange county': [
            'aliso viejo', 'dana point', 'fountain valley', 'la habra', 'laguna hills', 
            'lake forest', 'los alamitos', 'san clemente', 'san juan capistrano', 'seal beach'
        ],
        'san diego county': [
            'chula vista', 'encinitas', 'escondido', 'la mesa', 
            'lemon grove', 'national city', 'oceanside', 'poway', 'santee'
        ]
    },
    'illinois': {
        'cook county': [
            'evanston', 'mount prospect', 'oak lawn', 'orland park', 'palatine', 'skokie'
        ]
    },
    'texas': {
        'harris county': [
            'atascocita', 'baytown', 'bellaire', 'channelview', 'deer park', 
            'humble', 'la porte', 'seabrook', 'tomball'
        ]
    },
    'arizona': {
        'maricopa county': [
            'apache junction', 'avondale', 'buckeye', 'el mirage', 'fountain hills', 
            'goodyear', 'guadalupe', 'litchfield park', 'sun city', 'tolleson'
        ]
    },
    'washington': {
        'king county': [
            'auburn', 'burien', 'des moines', 'kenmore', 'kent', 
            'mercer island', 'redmond', 'renton', 'seatac', 'tukwila'
        ]
    },
    'oregon': {
        'washington county': [
            'hillsboro', 'tigard', 'sherwood', 'forest grove', 
            'cornelius', 'north plains', 'banks', 'gaston', 'durham', 'king city'
        ]
    },
    'nevada': {
        'clark county': [
            'boulder city', 'henderson', 'mesquite', 'moapa valley', 
            'paradise', 'spring valley', 'summerlin south', 'sunrise manor', 'whitney'
        ]
    },
    'florida': {
        'miami-dade county': [
            'aventura', 'cutler bay', 'doral', 'homestead', 
            'miami lakes', 'miami shores', 'palmetto bay', 'south miami', 'sweetwater'
        ]
    },
    'new york': {
        'suffolk county': [
            'babylon', 'bay shore', 'brentwood', 'central islip', 'commack', 
            'deer park', 'east northport', 'huntington', 'smithtown', 'west islip'
        ],
		'richmond county': [
            'annadale', 'arden heights', 'arlington', 'charleston', 'clifton',
            'concord', 'dongan hills', 'elm park', 'eltingville', 'great kills',
            'greenridge', 'huguenot', 'lighthouse hill', 'mariners harbor', 
            'midland beach', 'new dorp', 'oakwood', 'port richmond', 'princes bay',
            'richmondtown', 'rossville', 'st. george', 'todt hill', 'tottenville',
            'west brighton', 'westerleigh', 'woodrow'
        ]
    },
    'new jersey': {
        'bergen county': [
            'dumont', 'elmwood park', 'fair lawn', 'garfield', 
             'lodi', 'paramus', 'ridgewood'
        ]
    }
};

for (const state in counties) {
    for (const county in counties[state]) {
        if (reg.startsWith(state) && counties[state][county].includes(query)) {
            query = county;
            break;
        }
    }
}

//query = query || reg;
query = query !== '' ? query.concat(",", reg) : reg;

//console.log(query);

		//if (reg.startsWith('virginia') && fairfaxCities.includes(query)) {
		//		query = 'fairfax county';
		//} 
		
		   // query = query.concat(",", reg);

//console.log(query);

         // alert(query);
          if(query!='')
          {
            const filterItems = 
            locationData.find(item => item.full_name.toLowerCase().indexOf(query) == 0);
            data = filterItems;
            //alert(data.full_name);

            if(data == null || data == undefined || data.length == 0)
            { 
			  
               loc = "9073451"; lan = "en"; loc_name = "San Francisco Bay Area"; 
               fqry = GetEncodedQry(keyword, loc, lan);
               this.setState({gotLoc: true});
               this.setState({locfound: 0});
			   pt = "AI-Ranked Top Lawyers in San Francisco Bay Area (Default) | AI-Powered Lawyer Search";
			   document.description = "Find AI-Ranked Top-Rated Lawyers with AI-powered search. Get expert legal help and find the right lawyer for personal injury, divorce, car accidents, medical, tax, probate, family and more. Search, discover and connect with the best lawyers from our AI-ranked and screened lawyer directory"

               //alert(this.state.locfound);
            }
            else {
              pt = "AI-Ranked Top Lawyers Near " + data.full_name + " | AI-Powered Lawyer Search";
              loc = data.id;
              lan = data.language_code;
              loc_name = data.full_name;
              fqry = GetEncodedQry(keyword, loc, lan);
			  location = loc_name.split(',')[0].trim();
			  document.description = "Find AI-Ranked Top-Rated Lawyers near " + location + " with AI-powered search. Get expert legal help and find the right lawyer for personal injury, divorce, car accidents, medical, tax, probate, family and more. Search, discover and connect with the best lawyers from our AI-ranked and screened lawyer directory in " + loc_name 

              //alert(fqry);
              this.setState({locfound: 1});
              this.setState({gotLoc: true});
              }
           }
          else {
            // rtxt = JSON.stringify(data, null, 2)
            //data = null;
			//qry is empty
		   
              loc = "9067609"; lan = "en"; loc_name = "Manhattan,New York,United States"; 
               fqry = GetEncodedQry(keyword, loc, lan);
               this.setState({gotLoc: true});
               this.setState({locfound: 0});
			   pt = "AI-Ranked Top Lawyers in Manhattan, New York, United States (Default) | AI-Powered Lawyer Search";
			   document.description = "Find AI-Ranked Top-Rated Lawyers with AI-powered search. Get expert legal help and find the right lawyer for personal injury, divorce, car accidents, medical, tax, probate, family and more. Search, discover and connect with the best lawyers from our AI-ranked and screened lawyer directory"

              //alert(fqry);
              this.setState({locfound: 1});
              this.setState({gotLoc: true});
            }
         });
          }
          catch (e) { 
            //default when exception
            loc = "9073451"; lan = "en"; loc_name = "San Francisco Bay Area"; 
            fqry = GetEncodedQry(keyword, loc, lan);
			pt = "AI-Ranked Top Lawyers in San Francisco Bay Area (Default) | AI-Powered Lawyer Search";

			document.description = "Find AI-Ranked Top-Rated Lawyers with AI-powered search. Get expert legal help and find the right lawyer for personal injury, divorce, car accidents, medical, tax, probate, family and more. Search, discover and connect with the best lawyers from our AI-ranked and screened lawyer directory"

            this.setState({gotLoc: true});
            this.setState({locfound: 0});
            //alert(this.state.locfound);
            }
        }
         if (callsrch === 1) { 
		    fqry = GetEncodedQry(keyword, loc, lan);
		// Capitalize each word in the keyword
			let capitalizedKeyword = capitalizeEachWord(keyword);
            pt = "AI-Ranked Top " + capitalizedKeyword + " Near " + loc_name + " | AI-Powered Lawyer Search";
		location = loc_name.split(',')[0].trim();
		document.description = "Find AI-Ranked Top-rated " + capitalizedKeyword + " near " + location + " with AI-powered search. Get expert legal help and find the right lawyer for personal injury, divorce, car accidents, medical, tax, probate, family and more. Search, discover and connect with the best " + capitalizedKeyword + " from our AI-ranked and screened lawyer directory in " + loc_name 
            this.setState({gotLoc: true});
            this.setState({locfound: 1}); }

    }
   
    render() {
        
        return (
            <div>
            <div class="topmast"></div>
<div>
             {this.state.gotLoc === false && ishome ? <div id="preload" class="container card ver-hori-center">
                    <div class="csslogo"></div>
                    <br/>
                    <h5 class="card-title placeholder-glow">
        <span class="placeholder col-8"></span>
      </h5>
      <p class="card-text placeholder-glow">
        <span class="placeholder col-10"></span>
        <span class="placeholder col-12"></span>
        <span class="placeholder col-12"></span>
        <span class="placeholder col-12"></span>
        <span class="placeholder col-12"></span>
      </p>
      </div> : ''}
</div>
 {this.state.gotLoc === true && fqry != '' && this.state.locfound != null &&
      
		
 
 <div>
              {/* came.. {fqry}  {this.state.gotLoc} {this.state.locfound} */}
			  <HeroDiv keyword={capitalizeEachWord(keyword)} loc_name={loc_name} />
                <LawNavbar loc={loc} lan={lan} fqry={fqry} keyword={keyword} qid={qid} loc_name={loc_name} page={page}/>
      
               {/* <ChatBot /> */}
        
              <div> 
		<><Router>
                <Routes>
                    <Route exact path='/' element={<Search pt={pt} loc={loc} lan={lan} fqry={fqry} keyword={capitalizeEachWord(keyword)} qid={qid} loc_name={loc_name} page={page} callsrch={callsrch} locfound={this.state.locfound} />} />
                    <Route path='/search' element={<Search pt={pt} loc={loc} lan={lan} fqry={fqry} keyword={capitalizeEachWord(keyword)} qid={qid} loc_name={loc_name} page={page} callsrch={callsrch} locfound={this.state.locfound}/>} />
                    <Route path='/browse' element={<BrowseList loc={loc} lan={lan} fqry={fqry} qid={qid} loc_name={loc_name} page={page} />} />
						{/*<Route path='/cities'  element={<Cities/>}/>
						<Route path='/about'  element={<About/>}/>*/}
                    <Route path='/247-lawyers' element={<Navigate to='https://247.lawyerz.com' />} />
                    <Route path='/24-7-lawyers' element={<Navigate to='https://247.lawyerz.com' />} />
                    <Route path='/24hr-lawyers' element={<Navigate to='https://247.lawyerz.com' />} />
                    <Route path='/24-hr-lawyers' element={<Navigate to='https://247.lawyerz.com' />} />
                    <Route path='/247-lawyer' element={<Navigate to='https://247.lawyerz.com' />} />
                    <Route path='/24hr-lawyer' element={<Navigate to='https://247.lawyerz.com' />} />
                    <Route path='/24-hr-lawyers' element={<Navigate to='https://247.lawyerz.com' />} />
						{/*<Route path='/contact' element={<ContactForm/>*/}
					}/>

                    <Route path='*' element={<Navigate to='/' />} />
                    {/* <Route path='*' element={<Home />} /> */}

                </Routes>
				{ /*console.log('came..') */}
            </Router></>  
			</div> 
            </div> }
  
            
            </div>
                    
        );
    }
}

export { App }; 