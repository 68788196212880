import React, { Suspense, useEffect } from 'react';
import GetEncodedQry from '../GetEncodedQry';
import CheckIcon from '../components/CheckIcon';

const Links = React.lazy(() => import('../components/keywordlinks'));
const ApiReq = React.lazy(() => import('../ApiData'));

const BrowseList = (props) => {
    const queryParams = new URLSearchParams(window.location.search);
    const keyword = queryParams.get('q');
    const callsrch = keyword != null && props.loc != null ? 1 : 0;
	 // Function to capitalize the first letter of each word in a string
  const capitalizeEachWord = (string) => {
    return string.replace(/\b\w/g, char => char.toUpperCase());
  };
  const capKeyword = callsrch === 0 ? "Lawyers" : capitalizeEachWord(keyword);
    const pt = callsrch === 0 
        ? "AI-Powered Lawyer Directory - Lawyerz AI" 
        : `AI-Screened ${capitalizeEachWord(keyword)} near ${props.loc_name} | AI-Powered Lawyer Directory`;

  const location = callsrch === 0 ? "" : props.loc_name.split(',')[0].trim();

  document.description = callsrch === 0 
	? "Find AI-Ranked Top-rated Lawyers with AI-Powered Lawyer Directory. Get expert legal help and find the right lawyer for personal injury, divorce, car accidents, medical, tax, probate, family and more. Discover and connect with the best lawyer from our AI-Ranked and Screened Lawyer Directory" 
	
	: "Find AI-Ranked Top-rated " + capKeyword + " in " + location + " with AI-Powered Lawyer Directory. Get expert legal help and the right lawyer for personal injury, divorce, car accidents, medical, tax, probate, family and more. Find and connect with the best " + capKeyword + " near "  + props.loc_name + " from our AI-ranked and screened " + capKeyword + " Directory" 

    useEffect(() => {
        document.title = pt;
    }, [pt]);

    return (
        <div className="topdiv">
            {callsrch === 1 && (
                <div>
                    <h2 className="text-center">AI-Powered Lawyer Directory By Practice Area and Specialty</h2>
                    <div className="container-fluid trtags">
                        <Suspense fallback={<div>Loading...</div>}>
                            <Links count={3} />
                        </Suspense>
                    </div>
                    <div className="rsdiv">
                        <h3 className="p-3"> <CheckIcon /> AI-Screened {capitalizeEachWord(keyword)} in {props.loc_name}</h3>
                        <Suspense fallback={<div>Loading...</div>}>
                            <ApiReq formattedQry={props.fqry} page={props.page} query={keyword} qid={props.qid} />
                        </Suspense>
                    </div>
                    <div className="container-fluid trtags">
                        <Suspense fallback={<div>Loading...</div>}>
                            <Links count={5} />
                        </Suspense>
                    </div>
                </div>
            )}
            {callsrch === 0 && (
                <div>
				<div>
                    <h2 className="text-left" style={{ fontWeight:'500', fontSize: '1.2em', marginBottom: '24px', display: 'flex', marginLeft: '8%', verticalAlign: 'middle', background: '#2f2f2f', borderRadius: '30px', padding: '12px', alignItems: 'center'}}> <CheckIcon />  AI-Powered Lawyer Directory By Practice Area and Specialty</h2>
					</div>
					<div className="container-fluid text-center">
                        <Suspense fallback={<div>Loading...</div>}>
                            <Links />
                        </Suspense>
                    </div>
                    <input type="hidden" id="page" name="page" value="1" />
                </div>
            )}
        </div>
    );
}

export { BrowseList };
