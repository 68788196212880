import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import React, { Suspense } from 'react';

//const Spinner = React.lazy(() => import ('./spinner'));

const Locations = React.lazy(() => import ('../components/Locations'));

//import NavDropdown from 'react-bootstrap/NavDropdown';

export function LawNavbar(props) {

  return (
    <Navbar bg="warning" variant="dark" className='nopad navmain'>
      <Container>
        <Navbar.Brand href="/">
		  <div class="imgdiv">
           </div>
		 </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar id="basic-navbar-nav" className='block'>
          <Nav className="me-auto">
           
          </Nav>
          <Nav class="sbox-out">
            <Nav class="sbox-nav"><div>
               <Suspense fallback={<div>Loading...</div>}> <Locations selVal={props.loc} /></Suspense> 
            </div></Nav>
          </Nav> 
        </Navbar>
      </Container>
     </Navbar>
  )
}
export default LawNavbar;
