import React from 'react';

const HeroDiv = ({ keyword, loc_name }) => {
  // Extract substring until the first comma
  const location = loc_name.split(',')[0].trim();

  return (
    <div className="card hero">
      <div className="card-header">
        <div style={{ display: 'inline-flex' }}>
          <div id="heromsg">
            <h1 className="tctr">
              {keyword} in {location} <div>&nbsp;|</div> 
              <strong className="herolbl">Top-Rated AI-Ranked </strong>
            </h1>
            <div>
              <div 
                className="ai-tooltip" 
                data-tooltip="Lawyerz Search AI Mascot: Elephant Robot - Your AI-Search Companion"
              >
                <img 
                  src="ai-lawyer-search.jpg" 
                  alt="AI-Powered Lawyer Search With Lawyerz AI" 
                  className="mascothdr" 
                  align="right"
                />
              </div>
              <div className="subhero">
                <p>
                  Find the Right Lawyer with AI. Get instant access to top-rated lawyers for personal injury, divorce, car accidents, probate, estate planning, social security, medical malpractice, family law, tax issues, workers comp, and more. Our AI-powered search finds and connects you with the best lawyers and legal services from {keyword} directory in {loc_name} ranked by AI.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroDiv;
