import React, { Suspense, useEffect } from 'react';
import CheckIcon from '../components/CheckIcon'; // Adjust the import path as per your file structure

const Queries = React.lazy(() => import('../components/Queries'));
const Trending = React.lazy(() => import('../components/Trending'));
const ApiReq = React.lazy(() => import('../ApiData'));
//const CheckIcon = React.lazy(() => import('../components/CheckIcon'));


const Search = (props) => {
    useEffect(() => {
        document.title = props.pt;
		// console.log('pt..' + props.pt)

    }, [props.pt]);

    return (
        <div className="topdiv">
            <div>
                <div className="mtop"></div>
                <div className="container-fluid sboxdiv">
                    <div className="sbox">
                        <Suspense fallback={<div>Loading...</div>}>
                            <Queries selVal={props.qid} />
                        </Suspense>
                    </div>
                    <button type="submit" className="btn btn-primary btn-lg sbtn">
                        <span className="fa fa-search">&nbsp;</span>Search
                    </button>
                </div>
                <div className="container-fluid trtags">
                    <Suspense fallback={<div>Loading...</div>}>
                        <Trending />
                    </Suspense>
                </div>
                <div className="rsdiv">
                    <h3 className="p-3"> 
						<CheckIcon /> 
					  AI-Ranked Top-Rated {props.keyword} in {props.loc_name} {props.locfound === 0 ? '(Default)' : ''} </h3>
                    <Suspense fallback={<div>Loading...</div>}>

                        <ApiReq formattedQry={props.fqry} page={props.page} query={props.keyword} qid={props.qid} />
                    </Suspense>
                </div>
                <div className="container-fluid trtags">
                    <Suspense fallback={<div>Loading...</div>}>
                        <Trending count={5} />
                    </Suspense>
                </div>
            </div>
        </div>
    );
};

export { Search };
